<template>
  <div>
    <div class="header">
      <el-row type="flex" justify="space-between">
        <el-col :span="6">
          <div class="grid-content">
            <span class="left">操作人</span>
            <el-select
              class="right"
              v-model="opId"
              size="small"
              filterable
              remote
              reserve-keyword
              placeholder="请输入操作人"
              :remote-method="remoteMethod"
              :loading="opPersonLoading"
            >
              <el-option
                v-for="item in list"
                :key="item.userId"
                :label="item.loginName"
                :value="item.userId"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content">
            <span class="left">操作时间</span>
            <el-date-picker
              class="right"
              v-model="dateResult"
              type="daterange"
              size="small"
              value-format="yyyy-MM-dd"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content">
            <span class="left">操作模块</span>
            <el-select
              class="right"
              size="small"
              v-model="opModuleName"
              placeholder="请选择"
              @change="selectOpModuleRes"
            >
              <el-option
                v-for="item in opModuleList"
                :key="item.item"
                :label="item.name"
                :value="item.item"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="btn-content">
            <el-button type="primary" size="medium" @click="handleQuery"
              >查询</el-button
            >
            <el-button size="medium" @click="handleClear">清空</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <cesTable
      :isButton="false"
      :isSelection="false"
      :isIndex="true"
      :isPagination="true"
      :tableData="tableData"
      :tableCols="tableCols"
      :pagination="pagination"
      :loading="loading"
      @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
    ></cesTable>
  </div>
</template>

<script>
import cesTable from '@/components/table'
import { opLogList, opModuleList, userInfoList } from '@/request/api'
export default {
  components: {
    cesTable,
  },
  data() {
    return {
      loginName: '',
      list: [],
      opId: '',
      opModuleName: '',
      opModuleId: '',
      opModuleList: [],
      dateResult: '',
      tableData: [],
      opPersonLoading: false,
      tableCols: [
        { label: '操作项目', prop: 'operateName' },
        { label: '操作结果', prop: 'operateResult' },
        { label: '操作人', prop: 'operatorName' },
        { label: '操作账号', prop: 'contractMobile' },
        { label: 'IP', prop: 'requestIp' },
        { label: '操作时间', prop: 'created' },
        { label: '操作内容', prop: 'operateContent' },
      ],
      pagination: {
        pageSize: 20,
        pageNum: 1,
        total: 0,
      },
      loading: false,
    }
  },
  created() {
    this.queryList()
    this.queryModuleList()
  },
  methods: {
    async remoteMethod(query) {
      try {
        const p = { current: 1, size: 100, loginName: query }
        this.opPersonLoading = true
        const res = await userInfoList(p)
        this.opPersonLoading = false
        if (res.status === 200 && res.data) {
          this.list = res.data.items || []
        }
      } catch (error) {
        console.log(error)
      }
    },
    async queryModuleList() {
      const res = await opModuleList()
      if (res.status === 200 && res.data) {
        this.opModuleList = res.data || []
      }
    },
    async queryList() {
      const date1 = this.dateResult && this.dateResult[0]
      const date2 = this.dateResult && this.dateResult[1]
      const beginDate = date1 ? date1 + ' 00:00:00' : ''
      const endDate = date2 ? date2 + ' 23:59:59' : ''
      const p = {
        operateItem: this.opModuleId,
        operateTimeBegin: beginDate,
        operateTimeEnd: endDate,
        page: this.pagination.pageNum,
        size: this.pagination.pageSize,
        userId: this.opId,
      }
      try {
        this.loading = true
        const res = await opLogList(p)
        this.loading = false
        if (res.status && res.data) {
          this.tableData = res.data.items || []
          if (res.data.total && p.page === 1) {
            this.pagination.total = res.data.total
          }
        } else {
          this.$message.error(res.message)
        }
      } catch (error) {
        this.loading = false
      }
    },
    selectOpModuleRes(id) {
      this.opModuleId = id
    },
    handleCurrentChange(val) {
      this.pagination.pageNum = val
      this.queryList()
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val
      this.queryList()
    },
    handleQuery() {
      this.queryList()
    },
    handleClear() {
      this.opId = ''
      this.opName = ''
      this.dateResult = ''
      this.opModuleId = ''
      this.opModuleName = ''
    },
  },
}
</script>

<style lang="stylus" scoped>
.header {
    padding:10px 20px 10px 20px;
}

.el-row {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}

.grid-content {
    display: flex;
    align-items: center;
}

.left {
    margin-right: 20px;
    font-size: 14px;
}

.right {
    flex: 1;
}

.btn-content {
    display: flex;
    justify-content: flex-end;
}
</style>
